<template>
  <div class="abnormal_data_page">
    <h4>判定时间</h4>
    <p>
      近
      <el-input v-if="isEdit" class="input-box" size="mini" v-model="days" />
      <span v-else>{{ days }}</span>
      天内，监测店家的超标次数、净化器不正常使用天数
      <!-- <el-button size="mini" type="primary" v-if="isEdit" @click="saveData"
        >保存</el-button
      >
      <el-button size="mini" type="primary" v-else @click="isEdit = true">编辑</el-button> -->
    </p>
    <h4>健康码颜色规则</h4>
    <!-- 列表 -->
    <el-table
      :data="dataList"
      size="mini"
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column type="index" label="#" align="center" width="55" />
      <el-table-column
        prop="status"
        label="健康码"
        width="200"
        header-align="center"
      />
      <el-table-column
        prop="des"
        label="描述"
        show-overflow-tooltip
        header-align="center"
      />
    </el-table>
    <h4>数值设置</h4>
    <!-- 列表 -->
    <el-table
      :data="setList"
      size="mini"
      border
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column type="index" label="#" align="center" width="55" />
      <el-table-column
        prop="Desc"
        label="健康码"
        width="200"
        header-align="center"
      />
      <el-table-column prop="Value" label="数值" align="center">
        <template slot-scope="scope">
          Value1：
          <el-input
            v-if="scope.row.editable"
            class="input-box"
            size="mini"
            v-model="scope.row.Value1"
            style="margin-right: 30px;"
          >
          </el-input>
          <span v-else style="margin-right: 30px;">{{ scope.row.Value1 }}</span>
          Value2：
          <el-input
            v-if="scope.row.editable"
            class="input-box"
            size="mini"
            v-model="scope.row.Value2"
          >
          </el-input>
          <span v-else>{{ scope.row.Value2 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        header-align="center"
        width="80"
      >
        <template slot-scope="scope">
          <i
            @click="handleProcess(scope.$index, scope.row)"
            :class="
              scope.row.editable ? 'opt fa fa-floppy-o' : 'opt el-icon-edit'
            "
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      postdata: {},
      isEdit: false,
      post: {
        min01: undefined,
        min02: undefined,
        max01: undefined,
        max02: undefined,
      },
      days: 10,
      dataList: [
        {
          status: "绿色",
          des: "超标次数小于Value1次，且净化器不正常使用天数小于Value2天",
        },
        {
          status: "红色",
          des: "超标次数大于Value1次，或净化器不正常使用天数大于Value2天",
        },
        {
          status: "黄色",
          des: "介于绿码和红码之间",
        },
      ],
      setList: [],
    };
  },
  computed: {},
  mounted() {
    this.netGetMessageList(); // 获取列表
  },
  methods: {
    /**
     * @description 获取列表
     */
    netGetMessageList() {
      this.$post("admin/listHealthCodeColorRule")
        .then((res) => {
          this.setList = res;
        })
        .catch(() => {});
    },
    handleProcess(i, row) {
      if (row.editable) {
        this.$post("admin/updateHealthCodeColorRule", {
          id: row.Id,
          Value1: parseFloat(row.Value1),
          Value2: parseFloat(row.Value2),
        }).then((res) => {
          this.netGetMessageList();
        });
        // this.getData()
      }
      this.$set(
        this.setList,
        i,
        Object.assign(row, { editable: !row.editable })
      );
    },
    saveData() {},
  },
};
</script>

<style lang="scss" scoped>
.abnormal_data_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
}
.input-box {
  width: 55px;
}
</style>
